import React, { useState, useCallback, useRef, useEffect } from "react";
import axios from "axios";
import Flatpickr from "react-flatpickr";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Link } from "./link";
import CalendarTodayIcon from "../images/icons/material/calendar_today.svg";
import AccessTime from "../images/icons/material/access_time.svg";
import dayjs from "dayjs";
import "flatpickr/dist/l10n/it.js";
import "flatpickr/dist/themes/material_blue.css";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const NUMBER_REGEX = /^(\+)?[0-9\-\\/. ]{4,16}$/;
const FORM_ENDPOINT = process.env.GATSBY_FORM_CHIAMATE_ENDPOINT;
const GATSBY_FORM_GROUPID = process.env.GATSBY_FORM_GROUPID || "6179307";
const mandatoryFields = ["nome", "cognome", "data", "recapito", "privacy"];
const blankFields = [
  "telefono",
  "indirizzo",
  "email",
  "ragioneSociale",
  "partitaIva",
  "comune",
  "partitaIva",
];
const prefillFields = {
  companyId: "10201",
  groupId: GATSBY_FORM_GROUPID,
  contratto: "Commerciale",
  createDate: dayjs().format("YYYY-MM-DD HH:mm:ss.0"), //2017-04-04 09:28:07.0
  modifiedDate: dayjs().format("YYYY-MM-DD HH:mm:ss.0"), //2017-04-04 09:28:07.0
  ...blankFields.reduce((result, field) => ({ ...result, [field]: "" }), {}),
};
// data_ora 2017-04-04 09:28
// const numeri = numeriEngie.numeri;
const optionsDatePicker = {
  pickerDate: {
    locale: "it",
    dateFormat: "d/m/Y",
    monthSelectorType: "static",
    maxDate: new Date(),
    defaultDate: null,
  },
  pickerTime: {
    locale: "it",
    noCalendar: true,
    enableTime: true,
    time_24hr: true,
    defaultDate: null,
  },
};

const ContactFormVerificaChiamate = ({ csvUrl }) => {
  const [formData, setFormData] = useState({
    nome: "",
    cognome: "",
    data: "",
    ora: "",
    chiamato: "",
    chiamante: "",
    registro_opposizioni: false,
    nome_numero_operatore: "",
    descr_giacliente: false,
    descr_possesso_dati: false,
    descr_tenta_possesso_dati: false,
    descr_nulla: false,
    descrizione: "",
    recapito: "",
    richiestaDatiPersonali: "",
    datianagrafici: false,
    datidicontatto: false,
    podpdr: false,
    altridati: false,
    privacy: false,
    giaCliente: false,
    codiceCliente: "",
  });

  const [numeri, setNumeri] = useState(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (csvUrl) {
      const loadCsv = async () => {
        try {
          const response = await axios.get(csvUrl);
          if (response.status < 300 && response.status >= 200) {
            const csvContent = response.data;
            const numeri = csvContent
              .split("\n")
              .map((item) => item.trim().replace(/,/g, ""))
              .filter((item) => item && !isNaN(item.slice(0, 1)));
            console.log("Loaded numeri");
            setNumeri(numeri);
          } else {
            throw new Error(
              `Error calling ${csvUrl} status=${response.status} message=${response.statusText}`
            );
          }
        } catch (err) {
          console.error("Error loading numeri csv: ", err);
        }
      };
      loadCsv();
    }
  }, [csvUrl]);

  const formRef = useRef(null);

  const [submitted, setSubmitted] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [remoteError, setRemoteError] = useState(null);
  const [errorsMap, setErrorsMap] = useState({});

  const onChange = useCallback((e) => {
    const { name, value, checked, type } = e.target;
    setFormData((data) => ({
      ...data,
      [name]: type === "checkbox" ? checked : value,
    }));
  }, []);

  const onChangeDate = useCallback((date) => {
    setFormData((data) => ({
      ...data,
      data: dayjs(date[0]).format("YYYY-MM-DD"),
    }));
  }, []);
  const onChangeHour = useCallback((date) => {
    setFormData((data) => ({ ...data, ora: dayjs(date[0]).format("HH:mm") }));
  }, []);

  const [focusMap, setFocusMap] = useState({});

  const validateForm = useCallback((formData) => {
    const errors = mandatoryFields.reduce((errors, field) => {
      return { ...errors, [field]: !formData[field] };
    }, {});

    // Validazione descrizione accaduto
    if (
      !(
        formData.descr_giacliente ||
        formData.descr_possesso_dati ||
        formData.descr_tenta_possesso_dati ||
        formData.descr_nulla
      )
    ) {
      errors.descrizioneAccaduto = true;
    }

    // Validazione codice cliente se già cliente=true
    if (formData.giaCliente) {
      if (formData.codiceCliente === "") errors.codiceCliente = true;
    }

    setErrorsMap(errors);
    const formValid = Object.values(errors).find((val) => !!val) === undefined;
    if (!formValid) {
      setRemoteError(null);
    }
    return formValid;
  }, []);

  useEffect(() => {
    if (validationError) {
      const formValid = validateForm(formData);
      // console.log('formValid: ', formValid);
      if (formValid) {
        setValidationError(null);
      }
    }
  }, [validationError, formData, validateForm]);

  const onFocus = useCallback((e) => {
    const { name } = e.target;
    setFocusMap((map) => ({ ...map, [name]: true }));
  }, []);
  const onBlur = useCallback((e) => {
    const { name } = e.target;
    setFocusMap((map) => ({ ...map, [name]: false }));
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const [loading, setLoading] = useState(false);
  const submit = useCallback(
    async (e) => {
      e.preventDefault();
      const formDataToSubmit = new FormData(e.target);
      if (!validateForm(formData)) {
        console.warn("Form data not valid");
        setValidationError("Completa i dati mancanti");
        return;
      }

      if (!executeRecaptcha) {
        console.warn("Execute recaptcha not yet available");
        return;
      }
      Object.entries(prefillFields).forEach(([field, value]) =>
        formDataToSubmit.append(field, value)
      );
      // Merge data e ora
      const data = formDataToSubmit.get("data");
      const ora = formDataToSubmit.get("ora");

      let tipoDatiPersonali = [];
      if (formData.richiestaDatiPersonali.toLowerCase() === "si") {
        if (formData.datianagrafici) tipoDatiPersonali.push("Dati anagrafici");
        if (formData.datidicontatto) tipoDatiPersonali.push("Dati di contatto");
        if (formData.podpdr) tipoDatiPersonali.push("POD / PDR");
        if (formData.altridati) tipoDatiPersonali.push("Altro");
      } else {
        formDataToSubmit.set("richiestaDatiPersonali", "no");
      }

      let descrizioneOptions = [];
      if (formData.descr_giacliente) descrizioneOptions.push("1");
      if (formData.descr_possesso_dati) descrizioneOptions.push("2");
      if (formData.descr_tenta_possesso_dati) descrizioneOptions.push("3");
      if (formData.descr_nulla) descrizioneOptions.push("4");

      formDataToSubmit.set("tipoDatiPersonali", tipoDatiPersonali);
      formDataToSubmit.set("descrizioneAccaduto", descrizioneOptions);
      formDataToSubmit.delete("datianagrafici");
      formDataToSubmit.delete("datidicontatto");
      formDataToSubmit.delete("podpdr");
      formDataToSubmit.delete("altridati");
      formDataToSubmit.set("data_ora", ora ? `${data} ${ora}` : data);
      formDataToSubmit.delete("data");
      formDataToSubmit.delete("ora");
      formDataToSubmit.set("chiamante", formData.chiamante);
      formDataToSubmit.set(
        "registro_opposizioni",
        formDataToSubmit.get("registro_opposizioni") ? "true" : "false"
      );
      formDataToSubmit.set(
        "giaCliente",
        formDataToSubmit.get("giaCliente") ? "true" : "false"
      );
      formDataToSubmit.set("codiceCliente", formData.codiceCliente);

      const token = await executeRecaptcha("formCorporate");
      if (token) {
        formDataToSubmit.set("grecaptcha", token);
      }

      setValidationError(null);
      setRemoteError(null);
      const queryStringSubmit = new URLSearchParams(
        formDataToSubmit
      ).toString();
      console.log("About to submit: ", queryStringSubmit);
      setLoading(true);
      try {
        const { data, status } = await axios.post(
          `${FORM_ENDPOINT}`,
          queryStringSubmit,
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        );
        if (status === 200) {
          console.log("Submitted: ", data);
          setSubmitted(true);
          if (formRef.current) {
            formRef.current.scrollIntoView();
          }
        } else {
          console.warn("Si è verificato un errore: ", data);
          setRemoteError(
            data.message ||
              (data.error && data.error.message) ||
              "Si è verificato un errore"
          );
        }
      } catch (err) {
        console.error("error: ", err);
        if (err.response) {
          console.error("errore nella chiamata: ", err.response);
        }
        setSubmitted(false);
        setRemoteError("Si è verificato un errore");
      }
      setLoading(false);
    },
    [validateForm, formData, executeRecaptcha]
  );

  const groupClass = (name, filled) => {
    return `nj-form-group${focusMap[name] ? " is-focused" : ""}${
      filled || formData[name] ? " is-filled" : ""
    }${errorsMap[name] ? " has-warning" : ""}`;
  };

  const [telefono, setTelefono] = useState("");
  const [verifyState, setVerifyState] = useState("INITIAL");
  const resetVerify = useCallback((e) => {
    e.preventDefault();
    setVerifyState("INITIAL");
  }, []);
  const verify = useCallback(
    (e) => {
      e.preventDefault();
      if (telefono && NUMBER_REGEX.test(telefono) && numeri) {
        const checkValue = telefono.trim().replace(/\D/g, "");
        setVerifyState(
          numeri.includes(checkValue) ? "VERIFIED" : "NOT_VERIFIED"
        );
        setFormData((data) => ({ ...data, chiamante: telefono }));
        setTelefono("");
      }
    },
    [telefono, numeri]
  );

  // const onFillCaptcha = useCallback((data) => {
  //   console.log('Filled captcha: ', data);
  // }, []);

  return (
    <div ref={formRef} className="job-form py-5">
      <div className="container">
        <>
          {
            {
              INITIAL: (
                <>
                  <div className="row">
                    <div className="col-12 col-lg-10 offset-lg-1 text-center">
                      <i className="nj-icon nj-icon-expert nj-icon--blue-corporate size-96"></i>
                      <h3 className="job-form__title">
                        VERIFICA LE CHIAMATE RICEVUTE PER SCOPI COMMERCIALI
                      </h3>
                      <p className="text-blue-corporate font-weight-bold mb-6">
                      In questa sezione puoi verificare solamente le chiamate ricevute 
                      per scopi commerciali. Non sono riscontrabili le chiamate per 
                      la gestione del servizio (letture, autoletture, 
                      problemi di fatturazione, gestione dei reclami, 
                      recupero del credito, chiamate di conferma, ecc.)
                      </p>
                      <p>
                        Inserisci qui il numero di telefono dal quale hai
                        ricevuto chiamate e controlla se il numero di telefono
                        che ti ha chiamato per proporti offerte commerciali è
                        autorizzato da ENGIE.
                      </p>
                      <p>
                        Nel caso non fosse autorizzato ti invitiamo a non
                        considerare quanto ti è stato comunicato e a inviare una
                        segnalazione al nostro servizio di controllo qualità.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                      <div className={groupClass("telefono", telefono)}>
                        <div className="job-form__text">
                          <input
                            type="text"
                            className="nj-form-control"
                            name="telefono"
                            id="chiamateFormTelefono"
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onChange={(e) => setTelefono(e.target.value)}
                            value={telefono}
                          />
                          <label
                            htmlFor="chiamateFormTelefono"
                            className="nj-label-floating"
                          >
                            Numero di telefono che ti ha contattato
                          </label>
                          {/* <span className="nj-help">help</span> */}
                          {/* {errorsMap.telefono ? (
                          <span className="job-form__warning">Specificare telefono</span>
                        ) : null} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                      <div className="nj-form-group">
                        <button
                          type="button"
                          onClick={verify}
                          className="nj-btn nj-btn--primary"
                          disabled={
                            !telefono || !NUMBER_REGEX.test(telefono) || !numeri
                          }
                        >
                          Verifica numerazione
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ),
              VERIFIED: (
                <>
                  <div className="row">
                    <div className="col-12 col-lg-10 offset-lg-1 text-center">
                      <i className="nj-icon nj-icon-expert nj-icon--blue-corporate size-96"></i>
                      <h3 className="job-form__title">
                        NUMERAZIONE AUTORIZZATA
                      </h3>
                      <p className="text-blue-corporate font-weight-bold mb-6">
                        Il numero che hai inserito risulta tra quelli
                        autorizzati da ENGIE.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                      Torna alla pagina della privacy di ENGIE:{" "}
                      <Link to="/privacy/">Privacy</Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                      <div className="nj-form-group">
                        <button
                          type="button"
                          onClick={resetVerify}
                          className="nj-btn nj-btn--primary"
                        >
                          Verifica un altro numero
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ),
              NOT_VERIFIED: (
                <>
                  <div className="row">
                    <div className="col-12 col-lg-10 offset-lg-1 text-center">
                      <i className="nj-icon nj-icon-expert nj-icon--blue-corporate size-96"></i>
                      <h3 className="job-form__title">
                        NUMERAZIONE NON AUTORIZZATA
                      </h3>
                      <p className="text-blue-corporate font-weight-bold mb-6">
                        Il numero che hai inserito NON risulta tra quelli
                        autorizzati da ENGIE per attività commerciali. Ti
                        preghiamo di non prendere in nessuna considerazione
                        quanto ti è stato comunicato. Per favore compila il form
                        qui di seguito e aiutaci ad individuare chi usa il
                        nostro nome senza il nostro consenso
                      </p>
                    </div>
                  </div>
                  <>
                    {submitted ? (
                      <div className="row">
                        <div className="col-12 text-center">
                          <h3>
                            Grazie per aver compilato il form. Ti aggiorneremo
                            sulla segnalazione una volta verificate le
                            informazioni fornite
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <form onSubmit={submit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className={groupClass("nome")}>
                              <div className="contact-form__text">
                                <input
                                  type="text"
                                  className="nj-form-control"
                                  name="nome"
                                  id="contactFormNome"
                                  pattern="['a-zA-ZÀ-ÖØ-öø-ÿ ]+"
                                  onFocus={onFocus}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  value={formData.nome}
                                />
                                <label
                                  htmlFor="contactFormNome"
                                  className="nj-label-floating"
                                >
                                  Nome*
                                </label>
                              </div>
                            </div>
                            {errorsMap.nome ? (
                              <span className="contact-form__warning">
                                Specificare nome
                              </span>
                            ) : null}
                          </div>
                          <div className="col-md-6">
                            <div className={groupClass("cognome")}>
                              <div className="contact-form__text">
                                <input
                                  type="text"
                                  className="nj-form-control"
                                  name="cognome"
                                  id="contactFormCognome"
                                  pattern="['a-zA-ZÀ-ÖØ-öø-ÿ ]+"
                                  onFocus={onFocus}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  value={formData.cognome}
                                />
                                <label
                                  htmlFor="contactFormCognome"
                                  className="nj-label-floating"
                                >
                                  Cognome*
                                </label>
                                {/* <span className="nj-help">Your lastname must have 5 characters</span> */}
                              </div>
                            </div>
                            {errorsMap.cognome ? (
                              <span className="contact-form__warning">
                                Specificare cognome
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className={`nj-form-group nj-form-group--icon-right${
                                focusMap.data ? " is-focused" : ""
                              }${formData.data ? " is-filled" : ""}${
                                errorsMap.data ? " has-warning" : ""
                              }`}
                            >
                              <div className="contact-form__text">
                                <Flatpickr
                                  className="nj-form-control"
                                  name="data"
                                  id="contactFormData"
                                  value={
                                    formData.data
                                      ? dayjs(
                                          formData.data,
                                          "YYYY-MM-DD"
                                        ).format("DD/MM/YYYY")
                                      : null
                                  }
                                  options={optionsDatePicker.pickerDate}
                                  onFocus={onFocus}
                                  onBlur={onBlur}
                                  onChange={onChangeDate}
                                />
                                <label
                                  htmlFor="contactFormData"
                                  className="nj-label-floating"
                                >
                                  Data della chiamata*
                                </label>
                                <CalendarTodayIcon className="material-svg text-gray-900" />
                              </div>
                            </div>
                            {errorsMap.data ? (
                              <span className="contact-form__warning">
                                Specificare data della chiamata
                              </span>
                            ) : null}
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`nj-form-group nj-form-group--icon-right${
                                focusMap.ora ? " is-focused" : ""
                              }${formData.ora ? " is-filled" : ""}${
                                errorsMap.ora ? " has-warning" : ""
                              }`}
                            >
                              <div className="contact-form__text">
                                <Flatpickr
                                  className="nj-form-control"
                                  name="ora"
                                  id="contactFormOra"
                                  value={formData.ora}
                                  onChange={onChangeHour}
                                  options={optionsDatePicker.pickerTime}
                                  onFocus={onFocus}
                                  onBlur={onBlur}
                                />
                                <label
                                  htmlFor="contactFormOra"
                                  className="nj-label-floating"
                                >
                                  Ora della chiamata
                                </label>
                                <AccessTime className="material-svg text-gray-900" />
                              </div>
                            </div>
                            {errorsMap.ora ? (
                              <span className="contact-form__warning">
                                Specificare ora della chiamata
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className={groupClass("chiamato")}>
                              <div className="contact-form__text">
                                <input
                                  type="text"
                                  className="nj-form-control"
                                  name="chiamato"
                                  id="contactFormChiamato"
                                  pattern="\+?[0-9]+\s*[0-9]*\s*[0-9]*"
                                  onFocus={onFocus}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  value={formData.chiamato}
                                />
                                <label
                                  htmlFor="contactFormChiamato"
                                  className="nj-label-floating"
                                >
                                  Numero Chiamato
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className={groupClass("chiamante")}>
                              <div className="contact-form__text">
                                <input
                                  type="text"
                                  className="nj-form-control"
                                  name="chiamante"
                                  id="contactFormChiamante"
                                  onFocus={onFocus}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  value={formData.chiamante}
                                  disabled={true}
                                />
                                <label
                                  htmlFor="contactFormChiamante"
                                  className="nj-label-floating"
                                >
                                  Numero Chiamante
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="nj-form-group">
                              <div className="nj-checkbox">
                                <label className="font-weight-bold">
                                  <input
                                    type="checkbox"
                                    name="registro_opposizioni"
                                    checked={formData.registro_opposizioni}
                                    onChange={onChange}
                                  />
                                  Numero iscritto al registro opposizioni?
                                  <span className="nj-checkbox__decorator">
                                    <span className="nj-checkbox__check"></span>
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div
                              className={groupClass("nome_numero_operatore")}
                            >
                              <div className="contact-form__text">
                                <input
                                  type="text"
                                  className="nj-form-control"
                                  name="nome_numero_operatore"
                                  id="contactFormNomeNumeroOperatore"
                                  onFocus={onFocus}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  value={formData.nome_numero_operatore}
                                />
                                <label
                                  htmlFor="contactFormNomeNumeroOperatore"
                                  className="nj-label-floating"
                                >
                                  Chi l'ha contattata per conto di quale società
                                  ha dichiarato di chiamare?
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="nj-form-group mb-0">
                              <div className="mb-3">
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label className="nj-label-static">
                                  Descrizione di quanto accaduto
                                </label>
                              </div>
                              <div className="nj-checkbox nj-checkbox">
                                <label>
                                  <input
                                    onChange={onChange}
                                    checked={formData.descr_giacliente}
                                    name="descr_giacliente"
                                    type="checkbox"
                                  />
                                  Chi chiama sa che sono un cliente Engie
                                  <span className="nj-checkbox__decorator">
                                    <span className="nj-checkbox__check"></span>
                                  </span>
                                </label>
                              </div>
                              <div className="nj-checkbox nj-checkbox">
                                <label>
                                  <input
                                    onChange={onChange}
                                    checked={formData.descr_possesso_dati}
                                    name="descr_possesso_dati"
                                    type="checkbox"
                                  />
                                  Chi chiama è in possesso dei miei dati
                                  personali oltre al telefono ed al nome
                                  <span className="nj-checkbox__decorator">
                                    <span className="nj-checkbox__check"></span>
                                  </span>
                                </label>
                              </div>
                              <div className="nj-checkbox nj-checkbox">
                                <label>
                                  <input
                                    onChange={onChange}
                                    checked={formData.descr_tenta_possesso_dati}
                                    name="descr_tenta_possesso_dati"
                                    type="checkbox"
                                  />
                                  Chi chiama tenta di ottenere altri dati
                                  personali oltre al telefono ed al nome
                                  <span className="nj-checkbox__decorator">
                                    <span className="nj-checkbox__check"></span>
                                  </span>
                                </label>
                              </div>
                              <div className="nj-checkbox nj-checkbox">
                                <label>
                                  <input
                                    onChange={onChange}
                                    checked={formData.descr_nulla}
                                    name="descr_nulla"
                                    type="checkbox"
                                  />
                                  Chi chiama sembra non sapere nulla a parte
                                  nome e telefono
                                  <span className="nj-checkbox__decorator">
                                    <span className="nj-checkbox__check"></span>
                                  </span>
                                </label>
                              </div>
                              {errorsMap.descrizioneAccaduto ? (
                                <span className="contact-form__warning">
                                  Selezionare almeno un' opzione
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className={groupClass("descrizione")}>
                              <div className="job-form__textarea">
                                <textarea
                                  className="nj-form-control"
                                  id="contactFormDescrizione"
                                  name="descrizione"
                                  cols="30"
                                  rows="3"
                                  maxLength={500}
                                  onFocus={onFocus}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  value={formData.descrizione}
                                ></textarea>
                                <label
                                  htmlFor="contactFormDescrizione"
                                  className="nj-label-floating"
                                >
                                  <strong>Altro</strong>
                                </label>
                              </div>
                            </div>
                            {errorsMap.descrizione ? (
                              <span className="contact-form__warning">
                                Specificare descrizione
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="nj-form-group">
                              <div className="nj-checkbox">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="giaCliente"
                                    checked={formData.giaCliente}
                                    onChange={onChange}
                                  />
                                  Sei già cliente ENGIE?
                                  <span className="nj-checkbox__decorator">
                                    <span className="nj-checkbox__check"></span>
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {formData.giaCliente && (
                          <div className="row">
                            <div className="col-6">
                              <div className={groupClass("codiceCliente")}>
                                <div className="contact-form__text">
                                  <input
                                    type="text"
                                    className="nj-form-control"
                                    name="codiceCliente"
                                    id="contactFormCodiceCliente"
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    value={formData.codiceCliente}
                                    maxlength="13"
                                    minLength="13"
                                    pattern="[0-9]+"
                                    onKeyDown={handleKeyDown}
                                  />
                                  <label
                                    htmlFor="contactFormCodiceCliente"
                                    className="nj-label-floating"
                                  >
                                    Inserisci il codice utenza che trovi nella
                                    tua bolletta*
                                  </label>
                                  <p style={{ fontSize: "11px" }}>
                                    Il codice utenza è composto da 13 numeri,
                                    senza spazi. Puoi trovarlo nelle tue
                                    bollette o all'interno dell'app
                                  </p>
                                </div>
                              </div>
                              {errorsMap.codiceCliente ? (
                                <span className="contact-form__warning">
                                  Inserisci il codice cliente
                                </span>
                              ) : null}
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-12">
                            <div
                              className={groupClass("richiestaDatiPersonali")}
                            >
                              <div className="job-form__text">
                                <select
                                  defaultValue=""
                                  onFocus={onFocus}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  className="nj-form-control"
                                  name="richiestaDatiPersonali"
                                  id="contactFormDatiPersonali"
                                >
                                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                  <option value="" disabled></option>
                                  <option value="si">Si</option>
                                  <option value="no">No</option>
                                </select>
                                <label
                                  htmlFor="contactFormDatiPersonali"
                                  className="nj-label-floating"
                                >
                                  <strong>
                                    Hanno richiesto i tuoi dati personali?
                                  </strong>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {formData.richiestaDatiPersonali === "si" && (
                          <div className="row">
                            <div className="col-12">
                              <div className="nj-form-group mb-0">
                                <div className="mb-3">
                                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                  <label className="nj-label-static">
                                    Quali dati ti hanno chiesto?
                                  </label>
                                </div>
                                <div className="nj-checkbox nj-checkbox--inline">
                                  <label>
                                    <input
                                      onChange={onChange}
                                      checked={formData.datianagrafici}
                                      name="datianagrafici"
                                      type="checkbox"
                                    />
                                    Dati anagrafici
                                    <span className="nj-checkbox__decorator">
                                      <span className="nj-checkbox__check"></span>
                                    </span>
                                  </label>
                                </div>
                                <div className="nj-checkbox nj-checkbox--inline">
                                  <label>
                                    <input
                                      onChange={onChange}
                                      checked={formData.datidicontatto}
                                      name="datidicontatto"
                                      type="checkbox"
                                    />
                                    Dati di contatto (es: email)
                                    <span className="nj-checkbox__decorator">
                                      <span className="nj-checkbox__check"></span>
                                    </span>
                                  </label>
                                </div>
                                <div className="nj-checkbox nj-checkbox--inline">
                                  <label>
                                    <input
                                      onChange={onChange}
                                      checked={formData.podpdr}
                                      name="podpdr"
                                      type="checkbox"
                                    />
                                    POD/PDR
                                    <span className="nj-checkbox__decorator">
                                      <span className="nj-checkbox__check"></span>
                                    </span>
                                  </label>
                                </div>
                                <div className="nj-checkbox nj-checkbox--inline">
                                  <label>
                                    <input
                                      onChange={onChange}
                                      checked={formData.altridati}
                                      name="altridati"
                                      type="checkbox"
                                    />
                                    Altro
                                    <span className="nj-checkbox__decorator">
                                      <span className="nj-checkbox__check"></span>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-12">
                            <div className={groupClass("recapito")}>
                              <div className="contact-form__text">
                                <input
                                  type="text"
                                  className="nj-form-control"
                                  name="recapito"
                                  id="contactFormRecapito"
                                  onFocus={onFocus}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  value={formData.recapito}
                                />
                                <label
                                  htmlFor="contactFormRecapito"
                                  className="nj-label-floating"
                                >
                                  <strong>
                                    Recapito per le comunicazioni: indirizzo e/o
                                    e-mail *
                                  </strong>
                                </label>
                              </div>
                            </div>
                            {errorsMap.recapito ? (
                              <span className="contact-form__warning">
                                Specificare recapito
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p>
                              ENGIE ITALIA S.p.A., Titolare del trattamento, con
                              sede in Milano, Via Chiese n. 72, la informa che i
                              dati forniti o comunque raccolti in questo form
                              saranno utilizzati solo per dare riscontro alla
                              Sua segnalazione e per fini statistici. Il
                              conferimento dei dati è facoltativo, ma in
                              mancanza delle informazioni richieste non potremo
                              gestire la Sua richiesta. I dati, trattati solo
                              dai soggetti preposti dal titolare, non saranno
                              diffusi né comunicati a terzi, fermo restando che
                              potranno essere messi a disposizione delle
                              Autorità competenti per tutelare i Suoi interessi
                              e quelli di ENGIE ITALIA S.p.A. Per accedere ai
                              dati, aggiornarli, integrarli, modificarli,
                              opporsi a futuri trattamenti o chiederne la
                              cancellazione nei limiti previsti dalla vigente
                              normativa è sufficiente andare alla pagina web
                              www.engie.it nella sezione Privacy e seguire le
                              istruzioni.
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="nj-form-group mb-0">
                              <div className="nj-checkbox">
                                <label className="font-weight-bold">
                                  <input
                                    type="checkbox"
                                    name="privacy"
                                    checked={formData.privacy}
                                    onChange={onChange}
                                  />
                                  Confermo di aver preso visione
                                  dell'informativa*
                                  <span className="nj-checkbox__decorator">
                                    <span className="nj-checkbox__check"></span>
                                  </span>
                                </label>
                              </div>
                              {errorsMap.privacy ? (
                                <span className="job-form__warning">
                                  Acconsentire al trattamento dei dati
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 mb-3">
                            <span className="nj-help d-block">
                              * Campo obbligatorio
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="nj-form-group">
                              <button
                                type="submit"
                                className="nj-btn nj-btn--primary"
                                disabled={loading}
                              >
                                {loading ? (
                                  <>
                                    Loading...
                                    <div
                                      className="nj-spinner nj-spinner--xs ml-1"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <span>Conferma</span>
                                )}
                              </button>
                              {validationError ? (
                                <span className="ml-3 job-form__warning">
                                  {validationError}
                                </span>
                              ) : null}
                              {remoteError ? (
                                <span className="ml-3 job-form__warning">
                                  {remoteError}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div>Oppure</div>
                            <div className="mt-2">
                              <Link
                                to="/privacy/"
                                className="nj-btn nj-btn--light"
                              >
                                Torna alla privacy
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </>
                </>
              ),
            }[verifyState]
          }
        </>
      </div>
    </div>
  );
};

export default ContactFormVerificaChiamate;
